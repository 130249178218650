import React from "react";
import Guidelines from "components/AIWritingAssistant/Guidelines";
import Layout from "components/layout";
import ToolsBanner from "components/commontools/toolsBanner";
import { useLocation } from "@reach/router";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { useEffect, useState } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Loader from "components/loader";
import EditingOutput from "components/AIWritingAssistant/EditingOutput";
import EditingInput from "components/AIWritingAssistant/EditingInput";

const Result = () => {
  const [result, setResult] = useState("");
  const [status, setStatus] = useState("edited");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);

  const handleClick = () => {};

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shareId = params.get("share-id");

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${shareId}`
        );
        setResult(response);
        const { user_text: sourceText, source_language_code: languagecode } =
          response.meta;
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fetchAnalysis();
  }, [shareId]);

  const getWords = () => {
    const text =
      status === "original"
        ? result?.meta?.user_text
        : result?.result?.revised_text;
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-20px)]">
        <Loader />
      </div>
    );

  return (
    <Layout>
      <div className="bg-[#F5F5F5]">
        <div className="max-w-7xl mx-auto px-4 pb-[60px]">
          <div className="pt-5">
            <ToolsBreadcrumb />
          </div>
          <div className="max-w-7xl mx-auto px-4 py-[60px]">
            <ToolsBanner
              text="AI Writing Assistant"
              title="Improve Multilingual Texts Quickly and Easily Using AI"
              description="This AI Editing Tool makes text editing simpler than ever. Write custom instructions to tailor your text to your needs. This AI writing helper supports multiple languages. It’s the perfect tool for refining your writing with accuracy and speed."
            />
          </div>

          <div className="flex lg:flex-row flex-col gap-4 mb-8 ">
            <EditingInput status={status} setStatus={setStatus} result={result} getWords ={getWords} />
         
            <Guidelines
              guidelines={result?.meta?.guideline ?? ""}
              inputText=""
              handleClick={handleClick}
              page="result"
            />
          </div>

          <EditingOutput
            analysisResult={result?.result?.detailed_summary_of_revisions}
            guidelines={result?.meta?.guideline ?? ""}
          />
          <p className="text-[#424242] font-opensans text-base mt-12 text-center">
            This AI Editing Tool makes text editing simpler than ever. Write
            custom instructions to tailor your text to your needs. This AI
            writing helper supports multiple languages. It’s the perfect tool
            for refining your writing with accuracy and speed.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Result;
